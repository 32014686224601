import { FC } from 'react';

import useAccountTypeSwitch from 'src/components/AccountLogin/hooks/useAccountTypeSwitch';
import { useSelector } from 'src/hooks/useSelector';
import { UserType } from 'src/models/userType';

import ApplicantToEmployer from 'src/components/AccountLogin/components/AccountTypeSwitch/ApplicantToEmployer';
import EmployerToApplicant from 'src/components/AccountLogin/components/AccountTypeSwitch/EmployerToApplicant';

const AccountTypeSwitch: FC = () => {
    const anonymousUserType = useSelector((state) => state.anonymousUserType);
    const { onSwitchTo } = useAccountTypeSwitch();

    if (anonymousUserType === UserType.Applicant) {
        return <ApplicantToEmployer onSwitch={() => onSwitchTo(UserType.Employer)} />;
    }

    return <EmployerToApplicant onSwitch={() => onSwitchTo(UserType.Applicant)} />;
};

export default AccountTypeSwitch;
