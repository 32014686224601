import { RouterLocation } from 'connected-react-router';

import buttonClick from '@hh.ru/analytics-js-events/build/xhh/common/main/button_click';
import urlParser from 'bloko/common/urlParser';

import { useSelector } from 'src/hooks/useSelector';
import { UserType } from 'src/models/userType';

const getExpandURL = (userType: UserType, location: RouterLocation<unknown>) => {
    const url = urlParser(`${location.pathname}${location.search}`);
    url.params.role = userType;
    return url.href;
};

interface UseAccountTypeSwitchHook {
    onSwitchTo: (userType: UserType) => void;
}

const useAccountTypeSwitch = (): UseAccountTypeSwitchHook => {
    const location = useSelector((state) => state.router.location);

    const onSwitchTo = (userType: UserType) => {
        if (userType === UserType.Applicant || userType === UserType.Employer) {
            buttonClick({ buttonName: `switch_to_${userType}` });
        }

        window.location.assign(getExpandURL(userType, location));
    };

    return { onSwitchTo };
};

export default useAccountTypeSwitch;
