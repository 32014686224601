import { useEffect, useState } from 'react';
import { useForm } from 'react-final-form';

import {
    format,
    formatToReactComponent,
    type TranslatedComponent,
    translation,
    useSelector,
} from '@hh.ru/front-static-app';
import { VSpacingContainer, Title, Text, Button } from '@hh.ru/magritte-ui';

import Captcha from 'src/components/AccountLogin/CombinedLoginCards/ExpApplicantLoginCard/MultiStepForm/Captcha';
import {
    ApplicantLoginFormValues,
    AuthCredentialType,
} from 'src/components/AccountLogin/CombinedLoginCards/ExpApplicantLoginCard/MultiStepForm/types';
import {
    CODE_FIELD,
    OtpTypeMap,
} from 'src/components/AccountLogin/CombinedLoginCards/ExpApplicantLoginCard/MultiStepForm/utils';
import { useApplicantLoginContext } from 'src/components/AccountLogin/CombinedLoginCards/ExpApplicantLoginCard/context';
import { LoginByCodeErrorKey } from 'src/components/AccountLogin/CombinedLoginCards/ExpApplicantLoginCard/hooks/useLogin/types';
import useOtp from 'src/components/AccountLogin/CombinedLoginCards/ExpApplicantLoginCard/hooks/useOtp';
import { GenerateOtpResponseKey } from 'src/components/AccountLogin/CombinedLoginCards/ExpApplicantLoginCard/hooks/useOtp/types';
import OtpByCall from 'src/components/OTP/OtpByCall';
import { OtpOperationType } from 'src/models/otp';

import OtpCodeField from 'src/components/AccountLogin/CombinedLoginCards/ExpApplicantLoginCard/MultiStepForm/EnterOtpCodeStep/OtpCodeField';
import OtpCodeSender from 'src/components/AccountLogin/CombinedLoginCards/ExpApplicantLoginCard/MultiStepForm/EnterOtpCodeStep/OtpCodeSender';

const WHATS_APP = 'WHATS_APP';
const PHONE_CALL = 'PHONE_CALL';

const TrlKeys = {
    title: {
        common: 'applicant.login.step.enterOtpCode.title',
        commonCall: 'otp.confirm.header.call',
        [AuthCredentialType.Phone]: 'applicant.login.step.enterOtpCode.title.phone',
        [AuthCredentialType.Email]: 'applicant.login.step.enterOtpCode.title.email',
        [WHATS_APP]: 'applicant.login.step.enterOtpCode.title.whatsApp',
    },
    subtitle: {
        common: 'applicant.login.step.enterOtpCode.subtitle',
        emailTip: 'applicant.login.step.enterOtpCode.subtitle.email.tip',
        call: 'otp.confirm.sub.header.call',
    },
    action: {
        [AuthCredentialType.Phone]: 'applicant.login.step.enterOtpCode.action.changePhone',
        [AuthCredentialType.Email]: 'applicant.login.step.enterOtpCode.action.changeEmail',
    },
};

const EnterOtpCodeStep: TranslatedComponent = ({ trls }) => {
    const isWhatsAppOtp = useSelector((state) => state.otp.authType === WHATS_APP);
    const isPhoneCall = useSelector((state) => state.otp.authType === PHONE_CALL);

    const { verification, codeLength, onNextStep, onStepReplace } = useApplicantLoginContext();

    const { mutators, getState, submit } = useForm<ApplicantLoginFormValues>();
    const { credentialType, username } = getState().values;

    const [hasCaptcha, setHasCaptcha] = useState(false);

    const { sendOtpCode } = useOtp();

    const otpType = OtpTypeMap[credentialType];

    useEffect(() => {
        mutators.removeValue(CODE_FIELD);

        return () => {
            setHasCaptcha(false);
        };
    }, [mutators]);

    const handleSubmit = async (): Promise<void> => {
        const result = await submit();

        if (result?.code === LoginByCodeErrorKey.AccountNotFound) {
            onNextStep('enter-name');
        }
    };

    const handleSendCode = async (): Promise<number | undefined> => {
        const result = await sendOtpCode({
            otpType,
            login: username,
            operationType: OtpOperationType.ApplicantOtpAuth,
        });

        if (result.success) {
            setHasCaptcha(false);
            return result.data.otp.secondsUntilNextSend;
        }

        if (result.error === GenerateOtpResponseKey.Captcha) {
            setHasCaptcha(true);
        }

        return undefined;
    };

    const handleChangeCredentialButtonClick = (): void => {
        onStepReplace('enter-credentials');
    };

    return (
        <VSpacingContainer default={24}>
            {isPhoneCall && <OtpByCall login={username} />}
            <Title
                Element="h2"
                size="medium"
                alignment="center"
                description={
                    <>
                        <Text Element="p" typography="paragraph-2-regular" style="secondary">
                            {isPhoneCall ? (
                                <Text Element="span">{trls[TrlKeys.subtitle.call]}</Text>
                            ) : (
                                formatToReactComponent(trls[TrlKeys.subtitle.common], {
                                    '{0}': <Text Element="span">{username}</Text>,
                                })
                            )}
                        </Text>
                        {credentialType === AuthCredentialType.Email && (
                            <Text Element="p" typography="paragraph-2-regular" style="secondary">
                                {trls[TrlKeys.subtitle.emailTip]}
                            </Text>
                        )}
                    </>
                }
            >
                {isPhoneCall
                    ? trls[TrlKeys.title.commonCall]
                    : format(trls[TrlKeys.title.common], {
                          '{0}': trls[TrlKeys.title[isWhatsAppOtp ? WHATS_APP : credentialType]],
                      })}
            </Title>
            <OtpCodeField
                otpType={otpType}
                codeLength={codeLength}
                verification={verification}
                isDisabled={hasCaptcha}
                onSubmit={handleSubmit}
            />
            <Captcha />
            <VSpacingContainer default={12}>
                <OtpCodeSender onSend={handleSendCode} />
                <Button size="large" mode="secondary" style="accent" onClick={handleChangeCredentialButtonClick}>
                    {trls[TrlKeys.action[credentialType]]}
                </Button>
            </VSpacingContainer>
        </VSpacingContainer>
    );
};

export default translation(EnterOtpCodeStep);
