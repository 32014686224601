import { type ComponentProps, type ReactNode } from 'react';
import { Field } from 'react-final-form';

import { type TranslatedComponent, translation } from '@hh.ru/front-static-app';
import { Input, PhoneInput } from '@hh.ru/magritte-ui';

import {
    AuthCredentialType,
    type ApplicantLoginFormValues,
    type Validator,
} from 'src/components/AccountLogin/CombinedLoginCards/ExpApplicantLoginCard/MultiStepForm/types';
import {
    composeValidators,
    INVALID_EMAIL_ERROR,
    INVALID_PHONE_ERROR,
    MISSING_VALUE_ERROR,
    required,
    USERNAME_FIELD,
} from 'src/components/AccountLogin/CombinedLoginCards/ExpApplicantLoginCard/MultiStepForm/utils';
import { GenerateOtpResponseKey } from 'src/components/AccountLogin/CombinedLoginCards/ExpApplicantLoginCard/hooks/useOtp/types';
import PhoneVerificationError from 'src/components/ApplicantResumes/PhoneVerification/components/PhoneVerificationError';
import useAutofocus from 'src/hooks/useAutofocus';
import type { Country } from 'src/models/allowedCountriesForSmsRegistration';

import {
    ErrorMessageMap,
    getCountryNames,
    UsernameValidatorMap,
} from 'src/components/AccountLogin/CombinedLoginCards/ExpApplicantLoginCard/MultiStepForm/EnterCredentialsStep/CredentialField/utils';

const TrlKeys = {
    error: {
        [MISSING_VALUE_ERROR]: 'form.error.valueMissing',
        [INVALID_PHONE_ERROR]: 'applicant.login.form.error.wrongPhone',
        [INVALID_EMAIL_ERROR]: 'applicant.login.form.error.wrongEmail',
        [GenerateOtpResponseKey.BadLogin]: 'form.error.patternMismatch',
        [GenerateOtpResponseKey.CountryBlacklisted]: 'error.signup.login.restrictedCountry',
        [GenerateOtpResponseKey.Captcha]: 'error.signup.captcha.invalid',
        [GenerateOtpResponseKey.Unknown]: 'form.error.default',
    },
    placeholder: {
        [AuthCredentialType.Email]: 'applicant.login.step.enterCredentials.credentialType.email',
        [AuthCredentialType.Phone]: 'applicant.login.step.enterCredentials.credentialType.phone',
    },
};

interface CredentialFieldProps {
    value: string;
    credentialType: AuthCredentialType;
    allowedCountries: Country[];
    defaultCountry?: Country['code'];
    showPlaceholder?: boolean;
    onChange: (type: AuthCredentialType, value: string) => void;
}

const CredentialField: TranslatedComponent<CredentialFieldProps> = ({
    trls,
    value,
    credentialType,
    allowedCountries,
    defaultCountry,
    showPlaceholder,
    onChange,
}) => {
    const autofocusRef = useAutofocus();

    const getErrorMessage = (error?: keyof typeof TrlKeys.error): ReactNode => {
        if (!error) {
            return null;
        }

        if (error === GenerateOtpResponseKey.BadLogin) {
            return trls[TrlKeys.error[ErrorMessageMap[credentialType]]];
        }

        if (credentialType === AuthCredentialType.Phone && error === GenerateOtpResponseKey.Unknown) {
            return <PhoneVerificationError error={error} />;
        }

        return trls[TrlKeys.error[error]];
    };

    const validate: Validator = (value, allValues) => {
        const mustBeValidPattern = UsernameValidatorMap[(allValues as ApplicantLoginFormValues).credentialType];

        return composeValidators(required, mustBeValidPattern)(value);
    };

    return (
        <Field<ApplicantLoginFormValues['username']>
            name={USERNAME_FIELD}
            type="text"
            validate={validate}
            render={({ input, meta }) => {
                const submitError = meta.dirtySinceLastSubmit ? null : (meta.submitError as string);
                const error = (meta.error || submitError) as keyof typeof TrlKeys.error;
                const errorMessage = getErrorMessage(error);

                const props: ComponentProps<typeof Input> = {
                    ...input,
                    ref: autofocusRef,
                    size: 'large',
                    invalid: meta.touched && !!errorMessage,
                    errorMessage,
                    autoCapitalize: 'off',
                    autoCorrect: 'off',
                    spellCheck: false,
                    onBlur: undefined,
                    placeholder: showPlaceholder ? trls[TrlKeys.placeholder[credentialType]] : null,
                    onChange: (value) => {
                        input.onChange(value);
                        onChange(credentialType, value);
                    },
                };

                return (
                    <>
                        {credentialType === AuthCredentialType.Phone && (
                            <PhoneInput
                                phoneInputElementRef={autofocusRef}
                                defaultCountry={defaultCountry}
                                defaultNationalNumber={value}
                                countryNames={getCountryNames(allowedCountries)}
                                invalid={props.invalid}
                                errorMessage={
                                    typeof errorMessage === 'string' ? errorMessage : trls[TrlKeys.error.UNKNOWN]
                                }
                                onChange={({ phone, nationalNumber }) => {
                                    props.onChange(nationalNumber.length > 1 ? phone : '');
                                }}
                            />
                        )}
                        {credentialType === AuthCredentialType.Email && (
                            <Input {...props} value={value} inputMode="email" />
                        )}
                    </>
                );
            }}
        />
    );
};

export default translation(CredentialField);
