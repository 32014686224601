import { format, type TranslatedComponent, translation, useSelector } from '@hh.ru/front-static-app';
import { Button } from '@hh.ru/magritte-ui';

import { formatSeconds } from 'Utils/Dates';
import useTimer from 'src/hooks/useTimer';

const TrlKeys = {
    timer: 'applicant.login.step.enterOtpCode.timer',
    resend: 'applicant.login.step.enterOtpCode.action.resendCode',
};

interface OtpCodeSenderProps {
    onSend: () => Promise<number | undefined>;
}

const OtpCodeSender: TranslatedComponent<OtpCodeSenderProps> = ({ trls, onSend }) => {
    const { secondsUntilNextSend } = useSelector(({ otp }) => otp);
    const [secondsLeft, setTimer] = useTimer(secondsUntilNextSend);

    const isTicking = secondsLeft > 0;

    const handleClick = async (): Promise<void> => {
        const nextSecondsLeft = await onSend();
        setTimer(nextSecondsLeft ?? 0);
    };

    return (
        <Button
            size="large"
            style="accent"
            mode={isTicking ? 'tertiary' : 'primary'}
            disabled={isTicking}
            onClick={handleClick}
        >
            {isTicking ? format(trls[TrlKeys.timer], { '{0}': formatSeconds(secondsLeft) }) : trls[TrlKeys.resend]}
        </Button>
    );
};

export default translation(OtpCodeSender);
