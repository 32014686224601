import type { FC } from 'react';
import { Field } from 'react-final-form';

import AccountDeleteInactive from 'src/components/AccountDeleteInactive';
import AccountDeleteInactiveSelect, { DELETE_INACTIVE_TIME_DEFAULT } from 'src/components/AccountDeleteInactive/Select';
import type { ApplicantLoginFormValues } from 'src/components/AccountLogin/CombinedLoginCards/ExpApplicantLoginCard/MultiStepForm/types';
import { INACTIVE_ACCOUNT_DELETION_FIELD } from 'src/components/AccountLogin/CombinedLoginCards/ExpApplicantLoginCard/MultiStepForm/utils';

const InactiveAccountDeletionField: FC = () => {
    return (
        <AccountDeleteInactive
            renderSelect={() => (
                <Field<ApplicantLoginFormValues['deleteInactiveTime']>
                    name={INACTIVE_ACCOUNT_DELETION_FIELD}
                    type="select"
                    initialValue={DELETE_INACTIVE_TIME_DEFAULT}
                    render={({ input }) => {
                        return <AccountDeleteInactiveSelect {...input} />;
                    }}
                />
            )}
        />
    );
};

export default InactiveAccountDeletionField;
