import { forwardRef, type ReactElement } from 'react';

import { Button } from '@hh.ru/magritte-ui';

interface HeaderIconButtonProps {
    ariaLabel: string;
    icon: ReactElement;
    onClick?: VoidFunction;
}

const HeaderIconButton = forwardRef<HTMLButtonElement, HeaderIconButtonProps>(({ ariaLabel, icon, onClick }, ref) => {
    const handleClick = (): void => {
        if (onClick) {
            onClick();
        }
    };

    return (
        <Button
            ref={ref}
            aria-label={ariaLabel}
            icon={icon}
            size="small"
            mode="tertiary"
            hideLabel
            onClick={handleClick}
        />
    );
});

export default HeaderIconButton;
