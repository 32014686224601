import type { FC } from 'react';
import classNames from 'classnames';

import { useSelector } from '@hh.ru/front-static-app';

import { useIsZarplataPlatform } from 'src/hooks/usePlatform';

import styles from './styles.less';

const HeaderLogo: FC = () => {
    const logoDomain = useSelector(({ logos }) => logos.logoDomain);
    const isZp = useIsZarplataPlatform();

    const companyLogo = isZp ? `${logoDomain}-small` : logoDomain;

    // В случае успеха экспа избавиться от зависимости supernova стилей и вынести в отдельный компонент
    return (
        <a href="/" className={styles.link}>
            <div className={classNames([`supernova-logo_${companyLogo}`], styles.logo)} />
        </a>
    );
};

export default HeaderLogo;
