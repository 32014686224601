import type { FC } from 'react';

import Hhcaptcha from 'src/components/Hhcaptcha';
import Recaptcha from 'src/components/Recaptcha';

const Captcha: FC = () => {
    return (
        <>
            <Recaptcha className="account-login-recaptcha" />
            <Hhcaptcha size="large" />
        </>
    );
};

export default Captcha;
