import { createContext, useCallback, useContext, useMemo, useState, type FC, type PropsWithChildren } from 'react';

import type {
    ApplicantLoginFlow,
    ApplicantLoginStep,
} from 'src/components/AccountLogin/CombinedLoginCards/ExpApplicantLoginCard/types';
import type { Verification } from 'src/models/applicant/auth';

const DEFAULT_OTP_LENGTH = 4;

interface ApplicantLoginContextProps {
    flow: ApplicantLoginFlow | null;
    step: ApplicantLoginStep | null;
    initialStep: ApplicantLoginStep;
    verification: Verification | null;
    codeLength: number;
    onNextStep: (value: ApplicantLoginStep) => void;
    onPrevStep: VoidFunction;
    onStepReplace: (value: ApplicantLoginStep) => void;
    onFlowChange: (value: ApplicantLoginFlow | null) => void;
    onVerificationChange: (value: Verification) => void;
    onCodeLengthChange: (value: number) => void;
}

const ApplicantLoginContext = createContext<ApplicantLoginContextProps | null>(null);

interface ApplicantLoginProviderProps {
    initialFlow?: ApplicantLoginFlow | null;
    initialStep?: ApplicantLoginStep;
}

const ApplicantLoginProvider: FC<PropsWithChildren<ApplicantLoginProviderProps>> = ({
    children,
    initialFlow = null,
    initialStep = 'account-type-selection',
}) => {
    const [flow, setFlow] = useState<ApplicantLoginContextProps['flow']>(initialFlow);
    const [stepHistory, setStepHistory] = useState<ApplicantLoginStep[]>([initialStep]);
    const [verification, setVerification] = useState<ApplicantLoginContextProps['verification']>(null);
    const [codeLength, setCodeLength] = useState<ApplicantLoginContextProps['codeLength']>(DEFAULT_OTP_LENGTH);

    const step: ApplicantLoginContextProps['step'] = useMemo(() => {
        const lastStepIndex = stepHistory.length - 1;
        return lastStepIndex >= 0 ? stepHistory[lastStepIndex] : null;
    }, [stepHistory]);

    const onNextStep: ApplicantLoginContextProps['onNextStep'] = useCallback((value) => {
        setStepHistory((prev) => [...prev, value]);
    }, []);

    const onPrevStep: ApplicantLoginContextProps['onPrevStep'] = useCallback(() => {
        setStepHistory((prev) => (prev.length ? prev.slice(0, -1) : prev));
    }, []);

    const onStepReplace: ApplicantLoginContextProps['onStepReplace'] = useCallback((value) => {
        setStepHistory((prev) => (prev.length ? [...prev.slice(0, -1), value] : prev));
    }, []);

    const onFlowChange: ApplicantLoginContextProps['onFlowChange'] = useCallback((value) => {
        setFlow(value);
    }, []);

    const onVerificationChange: ApplicantLoginContextProps['onVerificationChange'] = useCallback((value) => {
        setVerification(value);
    }, []);

    const onCodeLengthChange: ApplicantLoginContextProps['onCodeLengthChange'] = useCallback((value) => {
        setCodeLength(value);
    }, []);

    const value: ApplicantLoginContextProps = useMemo(
        () => ({
            flow,
            step,
            initialStep,
            verification,
            codeLength,
            onNextStep,
            onPrevStep,
            onStepReplace,
            onFlowChange,
            onVerificationChange,
            onCodeLengthChange,
        }),
        [
            flow,
            step,
            initialStep,
            verification,
            codeLength,
            onNextStep,
            onPrevStep,
            onStepReplace,
            onFlowChange,
            onVerificationChange,
            onCodeLengthChange,
        ]
    );

    return <ApplicantLoginContext.Provider value={value}>{children}</ApplicantLoginContext.Provider>;
};

const useApplicantLoginContext = (): ApplicantLoginContextProps => {
    const context = useContext<ApplicantLoginContextProps | null>(ApplicantLoginContext);

    if (!context) {
        throw new Error('Calling useApplicantLoginContext outside of ApplicantLoginProvider');
    }

    return context;
};

export { useApplicantLoginContext, ApplicantLoginContext };
export default ApplicantLoginProvider;
