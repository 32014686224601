import { ComponentType } from 'react';

import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { arrayToHumanReadableList } from 'src/utils/humanReadableList';

const TrlKeys = {
    errors: {
        UNKNOWN: 'phoneVerificationPage.error.unknown',
        BAD_LOGIN: 'phoneVerificationPage.error.BAD_PHONE',
        NULL_PHONE: 'phoneVerificationPage.error.NULL_PHONE',
        EMPTY: 'phoneVerificationPage.error.NULL_PHONE',
        NEED_PHONE: 'phoneVerificationPage.needPhone',
        COUNTRY_BLACKLISTED: 'phoneVerificationPage.error.restrictedCountry',
        CODE_SEND_BLOCKED: 'rb.edit.contacts.phone.verify.send.error.CODE_SEND_BLOCKED',
        CODE_NOT_FOUND: 'phoneVerificationPage.error.CODE_NOT_FOUND',
        CONFIRM_CODE_BLOCKED: 'account.connect.merge_by_code.email.error.CONFIRM_CODE_BLOCKED.phone',
        WRONG_CODE: 'phoneVerificationPage.error.WRONG_CODE',
        CODE_EXPIRED: 'phoneVerificationPage.error.CODE_EXPIRED',
    },

    countries: 'applicant.resumes.phoneVerification.countries',
    and: 'applicant.resumes.phoneVerification.countries.and',
    country: {
        RU: 'applicant.resumes.phoneVerification.countries.RU',
        UA: 'applicant.resumes.phoneVerification.countries.UA',
        BY: 'applicant.resumes.phoneVerification.countries.BY',
        UZ: 'applicant.resumes.phoneVerification.countries.UZ',
        AZ: 'applicant.resumes.phoneVerification.countries.AZ',
        KZ: 'applicant.resumes.phoneVerification.countries.KZ',
        GE: 'applicant.resumes.phoneVerification.countries.GE',
        KG: 'applicant.resumes.phoneVerification.countries.KG',
    },
};

const PhoneVerificationError: ComponentType<{ error: string; extra?: string }> = translation(
    ({ error, extra = '', trls }) => {
        const countriesList = useSelector((state) => state.allowedSMSCountries);

        const formErrorReasonText = (error: string) => {
            let reasonText = trls[TrlKeys.errors[error as keyof typeof TrlKeys.errors] || TrlKeys.errors.UNKNOWN];
            if (error === 'COUNTRY_BLACKLISTED' && countriesList) {
                const countriesTrls = Object.values(countriesList)?.map(
                    (country) => trls[TrlKeys.country[country as keyof typeof TrlKeys.country]]
                );
                reasonText = trls[TrlKeys.countries].replace(
                    '{0}',
                    arrayToHumanReadableList(countriesTrls, trls[TrlKeys.and])
                );
            }
            reasonText = reasonText.replace('{0}', extra);
            return reasonText;
        };

        if (!error) {
            return null;
        }

        return <div data-qa="resumes-page-verification__error-message">{formErrorReasonText(error)}</div>;
    }
);

export default PhoneVerificationError;
