import { useRef, type ComponentType, type FC } from 'react';
import { Form as FinalForm } from 'react-final-form';

import { useApplicantLoginContext } from 'src/components/AccountLogin/CombinedLoginCards/ExpApplicantLoginCard/context';
import type { ApplicantLoginStep } from 'src/components/AccountLogin/CombinedLoginCards/ExpApplicantLoginCard/types';
import VKAppListener from 'src/components/ApplicantSignInSignUpFlow/VKAppListener';
import Form from 'src/components/Form';
import mutators from 'src/utils/forms/mutators';

import AccountTypeSelectionStep from 'src/components/AccountLogin/CombinedLoginCards/ExpApplicantLoginCard/MultiStepForm/AccountTypeSelectionStep';
import EnterCredentialsStep from 'src/components/AccountLogin/CombinedLoginCards/ExpApplicantLoginCard/MultiStepForm/EnterCredentialsStep';
import EnterNameStep from 'src/components/AccountLogin/CombinedLoginCards/ExpApplicantLoginCard/MultiStepForm/EnterNameStep';
import EnterOtpCodeStep from 'src/components/AccountLogin/CombinedLoginCards/ExpApplicantLoginCard/MultiStepForm/EnterOtpCodeStep';
import EnterPasswordStep from 'src/components/AccountLogin/CombinedLoginCards/ExpApplicantLoginCard/MultiStepForm/EnterPasswordStep';
import SocialLoginStep from 'src/components/AccountLogin/CombinedLoginCards/ExpApplicantLoginCard/MultiStepForm/SocialLoginStep';
import type { ApplicantLoginFormValues } from 'src/components/AccountLogin/CombinedLoginCards/ExpApplicantLoginCard/MultiStepForm/types';
import useApplicantLoginForm from 'src/components/AccountLogin/CombinedLoginCards/ExpApplicantLoginCard/MultiStepForm/useForm';

const StepMap: Record<ApplicantLoginStep, ComponentType> = {
    'account-type-selection': AccountTypeSelectionStep,
    'enter-credentials': EnterCredentialsStep,
    'social-login': SocialLoginStep,
    'enter-otp-code': EnterOtpCodeStep,
    'enter-password': EnterPasswordStep,
    'enter-name': EnterNameStep,
};

interface MultiStepFormProps {
    isSignupPage?: boolean;
}

const MultiStepForm: FC<MultiStepFormProps> = ({ isSignupPage = false }) => {
    const formRef = useRef<HTMLFormElement>(null);
    const { step, initialStep, onVerificationChange, onCodeLengthChange } = useApplicantLoginContext();
    const { initialValues, onSubmit } = useApplicantLoginForm({
        step,
        isSignupPage,
        onVerificationChange,
        onCodeLengthChange,
    });

    const ActiveStep = StepMap[step ?? initialStep];

    return (
        <FinalForm<ApplicantLoginFormValues>
            initialValues={initialValues}
            mutators={mutators}
            onSubmit={onSubmit}
            render={({ form, handleSubmit }) => (
                <VKAppListener<ApplicantLoginFormValues> form={form} fields={['username', 'firstName', 'lastName']}>
                    <Form ref={formRef} onSubmit={handleSubmit}>
                        <ActiveStep />
                    </Form>
                </VKAppListener>
            )}
        />
    );
};

export default MultiStepForm;
