import { Text, Link } from '@hh.ru/magritte-ui';
import { SPALink } from '@hh.ru/redux-spa-middleware';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { formatToReactComponent } from 'bloko/common/trl';

import translation from 'src/components/translation';
import { useIsRussia } from 'src/hooks/useCountries';
import { useIsZarplataPlatform } from 'src/hooks/usePlatform';
import { useSelectorNonNullable } from 'src/hooks/useSelector';

const TrlKeys = {
    zp: {
        text: 'signup.agreementText.zp.text',
        continue: 'signup.agreementText.zp.continue',
        link: 'signup.agreementText.zp.link',
        pd: 'signup.agreementText.zp.pd',
    },
    common: {
        text: 'signup.agreementText.withSocial.text',
    },
    russia: {
        text: 'signup.agreementText.withSocial.russia.text',
        personalDataLink: 'signup.agreementText.russia.personal-data',
    },
    agreementTextLink: 'signup.agreementText.withSocial.link',
    continue: 'signup.agreementText.withSocial.buttonType.continue',
};

const Agreement: TranslatedComponent<{ isApplicant?: boolean }> = ({ trls, isApplicant }) => {
    const agreementURL = useSelectorNonNullable((state) => state.authUrl['agreement-form']);
    const personalDataRussiaURL = useSelectorNonNullable((state) => state.authUrl['policy-url']);
    const isRussia = useIsRussia();
    const isZP = useIsZarplataPlatform();

    if (!isApplicant) {
        return null;
    }

    if (isZP) {
        return (
            <Text style="primary" typography="label-3-regular">
                {formatToReactComponent(trls[TrlKeys.zp.text], {
                    '{0}': trls[TrlKeys.zp.continue],
                    '{1}': (
                        <Link
                            Element={SPALink}
                            to={agreementURL}
                            inline
                            typography="label-3-regular"
                            data-qa="account-signup-agreement"
                        >
                            {trls[TrlKeys.zp.link]}
                        </Link>
                    ),
                    '{2}': (
                        <Link
                            inline
                            typography="label-3-regular"
                            data-qa="account-signup-zp-pd"
                            Element={SPALink}
                            to="https://hhcdn.ru/file/17755941.doc"
                        >
                            {trls[TrlKeys.zp.pd]}
                        </Link>
                    ),
                })}
            </Text>
        );
    }

    return (
        <Text style="primary" typography="label-3-regular">
            {formatToReactComponent(isRussia ? trls[TrlKeys.russia.text] : trls[TrlKeys.common.text], {
                '{0}': trls[TrlKeys.continue],
                '{1}': (
                    <Link
                        Element={SPALink}
                        to={agreementURL}
                        inline
                        typography="label-3-regular"
                        data-qa="account-signup-agreement"
                    >
                        {trls[TrlKeys.agreementTextLink]}
                    </Link>
                ),
                ...(isRussia && {
                    '{2}': (
                        <Link
                            Element={SPALink}
                            to={personalDataRussiaURL}
                            inline
                            typography="label-3-regular"
                            data-qa="account-signup-personal-data-russia"
                        >
                            {trls[TrlKeys.russia.personalDataLink]}
                        </Link>
                    ),
                }),
            })}
        </Text>
    );
};

export default translation(Agreement);
