import { AuthMethod } from 'src/components/AccountLogin/types';
import { useSelector } from 'src/hooks/useSelector';

const isValidAuthMethod = (authMethod: string): authMethod is AuthMethod => {
    return Object.values(AuthMethod).includes(authMethod as AuthMethod);
};

const useAuthMethodParam = (): AuthMethod | null => {
    const queryParams = useSelector((state) => state.router?.location?.query);
    return isValidAuthMethod(queryParams?.authMethod) ? queryParams?.authMethod : null;
};

export default useAuthMethodParam;
