import type { FC } from 'react';

import HeaderGoBackButton from 'src/components/AccountLogin/CombinedLoginCards/ExpApplicantLoginCard/Header/HeaderGoBackButton';
import HeaderHint from 'src/components/AccountLogin/CombinedLoginCards/ExpApplicantLoginCard/Header/HeaderHint';
import HeaderLogo from 'src/components/AccountLogin/CombinedLoginCards/ExpApplicantLoginCard/Header/HeaderLogo';

import styles from './styles.less';

const Header: FC = () => {
    return (
        <header className={styles.header}>
            <HeaderGoBackButton />
            <HeaderLogo />
            <HeaderHint />
        </header>
    );
};

export default Header;
