import { formatToReactComponent, type TranslatedComponent, translation, useSelector } from '@hh.ru/front-static-app';
import { Link, Text } from '@hh.ru/magritte-ui';

import { useIsRussia } from 'src/hooks/useCountries';
import { useIsZarplataPlatform } from 'src/hooks/usePlatform';

import styles from './styles.less';

const ZP_PRIVACY_POLICY_URL = 'https://hhcdn.ru/file/17755941.doc';

const TrlKeys = {
    text: {
        ru: 'applicant.login.step.enterCredentials.legal.text.ru',
        common: 'applicant.login.step.enterCredentials.legal.text.common',
    },
    agreement: 'applicant.login.step.enterCredentials.legal.agreement',
    privacyPolicy: 'applicant.login.step.enterCredentials.legal.privacyPolicy',
};

const LegalInfo: TranslatedComponent = ({ trls }) => {
    const authUrl = useSelector((state) => state.authUrl);
    const isRussia = useIsRussia();
    const isZP = useIsZarplataPlatform();

    const privacyPolicyUrl = isZP ? ZP_PRIVACY_POLICY_URL : authUrl?.['policy-url'];
    const agreementUrl = authUrl?.['agreement-form'];

    return (
        <div className={styles.legal}>
            <Text typography="label-3-regular" style="secondary">
                {isRussia
                    ? formatToReactComponent(trls[TrlKeys.text.ru], {
                          '{0}': (
                              <Link inline href={privacyPolicyUrl} target="_blank" typography="label-3-regular">
                                  {trls[TrlKeys.privacyPolicy]}
                              </Link>
                          ),
                          '{1}': (
                              <Link inline href={agreementUrl} target="_blank" typography="label-3-regular">
                                  {trls[TrlKeys.agreement]}
                              </Link>
                          ),
                      })
                    : formatToReactComponent(trls[TrlKeys.text.common], {
                          '{0}': (
                              <Link inline href={agreementUrl} target="_blank" typography="label-3-regular">
                                  {trls[TrlKeys.agreement]}
                              </Link>
                          ),
                      })}
            </Text>
        </div>
    );
};

export default translation(LegalInfo);
