import axios from 'axios';

import prepareFormData from 'Modules/prepareFormData';
import fetcher from 'src/utils/fetcher';

import {
    GenerateOtpResponseKey,
    type PostGenerateOtpPayload,
    type PostGenerateOtpResult,
} from 'src/components/AccountLogin/CombinedLoginCards/ExpApplicantLoginCard/hooks/useOtp/types';

const GENERATE_OTP_URL = '/account/otp_generate';

export const postGenerateOtp = async (payload: PostGenerateOtpPayload): Promise<PostGenerateOtpResult> => {
    try {
        const formData = prepareFormData(payload) as unknown as PostGenerateOtpPayload;
        const response = await fetcher.post(GENERATE_OTP_URL, formData);

        return { success: true, data: response.data };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            throw error;
        }

        return { success: false, error: GenerateOtpResponseKey.Unknown };
    }
};
