import axios from 'axios';

import fetcher from 'src/utils/fetcher';

import type {
    PostSignUpError,
    PostSignUpPayload,
    PostSignUpResult,
    SignUpBody,
    SignUpData,
} from 'src/components/AccountLogin/CombinedLoginCards/ExpApplicantLoginCard/hooks/useSignUp/types';

const SIGN_UP_URL = '/account/signup';

const isPostSignUpError = (error: unknown): error is PostSignUpError => {
    if (!error) {
        return false;
    }

    const keys: (keyof PostSignUpError)[] = ['backurl', 'code', 'error', 'errors', 'hhcaptcha', 'recaptcha'];

    return keys.every((key) => key in (error as PostSignUpError));
};

declare global {
    interface FetcherPostApi {
        [SIGN_UP_URL]: {
            queryParams: void;
            body: SignUpBody;
            response: SignUpData;
        };
    }
}

export const postSignUp = async (payload: PostSignUpPayload): Promise<PostSignUpResult> => {
    try {
        const response = await fetcher.post(SIGN_UP_URL, payload);

        return { success: true, data: response.data };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            const errorData = error.response?.data as PostSignUpError;

            if (isPostSignUpError(errorData)) {
                return { success: false, error: errorData };
            }
        }

        throw error;
    }
};
