import { type ReactNode } from 'react';

import { type TranslatedComponent, translation } from '@hh.ru/front-static-app';

import NameField from 'src/components/AccountLogin/CombinedLoginCards/ExpApplicantLoginCard/MultiStepForm/EnterNameStep/NameField';
import {
    FIRST_NAME_FIELD,
    MISSING_VALUE_ERROR,
} from 'src/components/AccountLogin/CombinedLoginCards/ExpApplicantLoginCard/MultiStepForm/utils';
import { SignUpErrorKey } from 'src/components/AccountLogin/CombinedLoginCards/ExpApplicantLoginCard/hooks/useSignUp/types';

const TrlKeys = {
    placeholder: 'newApplicant.firstName',
    error: {
        [MISSING_VALUE_ERROR]: 'form.error.valueMissing',
        [SignUpErrorKey.Empty]: 'form.error.valueMissing',
        [SignUpErrorKey.Invalid]: 'error.signup.name_invalid.mobile',
        [SignUpErrorKey.Unknown]: 'form.error.default',
    },
};

const FirstNameField: TranslatedComponent = ({ trls }) => {
    const getErrorMessage = (error?: string | null): ReactNode =>
        error ? trls[TrlKeys.error[error as keyof typeof TrlKeys.error]] : null;

    return (
        <NameField
            name={FIRST_NAME_FIELD}
            placeholder={trls[TrlKeys.placeholder]}
            isAutofocus
            getErrorMessage={getErrorMessage}
        />
    );
};

export default translation(FirstNameField);
