import type { FC } from 'react';

import { urlParser } from '@hh.ru/browser-api-utils';
import { useSelector } from '@hh.ru/front-static-app';
import { ChevronLeftOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import { usePush } from '@hh.ru/redux-spa-middleware';

import HeaderIconButton from 'src/components/AccountLogin/CombinedLoginCards/ExpApplicantLoginCard/Header/HeaderIconButton';
import { useApplicantLoginContext } from 'src/components/AccountLogin/CombinedLoginCards/ExpApplicantLoginCard/context';

const INDEX_PAGE_PATH = '/';

const HeaderGoBackButton: FC = () => {
    const { step, initialStep, onPrevStep } = useApplicantLoginContext();
    const { search } = useSelector((state) => state.router.location);
    const { params } = urlParser(search);
    const push = usePush();

    const backUrl = params.backurl ? String(params.backurl) : INDEX_PAGE_PATH;

    const handleClick = (): void => {
        if (step === initialStep) {
            push(backUrl);
            return;
        }

        onPrevStep();
    };

    return <HeaderIconButton ariaLabel="go-back-button" icon={<ChevronLeftOutlinedSize24 />} onClick={handleClick} />;
};

export default HeaderGoBackButton;
