import type { ReactNode, FC } from 'react';
import { Field } from 'react-final-form';

import { Input } from '@hh.ru/magritte-ui';

import type { ApplicantLoginFormValues } from 'src/components/AccountLogin/CombinedLoginCards/ExpApplicantLoginCard/MultiStepForm/types';
import { required } from 'src/components/AccountLogin/CombinedLoginCards/ExpApplicantLoginCard/MultiStepForm/utils';

interface NameFieldProps {
    name: Extract<keyof ApplicantLoginFormValues, 'firstName' | 'lastName'>;
    placeholder: string;
    isAutofocus?: boolean;
    getErrorMessage: (error: string | null) => ReactNode;
}

const NameField: FC<NameFieldProps> = ({ name, placeholder, isAutofocus = false, getErrorMessage }) => {
    return (
        <Field<ApplicantLoginFormValues[typeof name]>
            name={name}
            type="text"
            validate={required}
            render={({ input, meta }) => {
                const submitError = meta.dirtySinceLastSubmit ? null : (meta.submitError as string);
                const error = (meta.error as string) || submitError;
                const errorMessage = getErrorMessage(error);

                return (
                    <Input
                        {...input}
                        placeholder={placeholder}
                        size="large"
                        invalid={meta.touched && !!errorMessage}
                        errorMessage={errorMessage}
                        autoCapitalize="off"
                        autoCorrect="off"
                        autoFocus={isAutofocus}
                        spellCheck={false}
                        onBlur={undefined}
                    />
                );
            }}
        />
    );
};

export default NameField;
