import type { ChangeEventHandler } from 'react';
import { Field } from 'react-final-form';

import { translation, type TranslatedComponent } from '@hh.ru/front-static-app';
import { Segmented, Segment } from '@hh.ru/magritte-ui';

import {
    AuthCredentialType,
    type ApplicantLoginFormValues,
} from 'src/components/AccountLogin/CombinedLoginCards/ExpApplicantLoginCard/MultiStepForm/types';
import { CREDENTIAL_TYPE_FIELD } from 'src/components/AccountLogin/CombinedLoginCards/ExpApplicantLoginCard/MultiStepForm/utils';

const isCredentialType = (value: string): value is AuthCredentialType => {
    return Object.values(AuthCredentialType).includes(value as AuthCredentialType);
};

const TrlKeys = {
    [AuthCredentialType.Phone]: 'applicant.login.step.enterCredentials.credentialType.phone',
    [AuthCredentialType.Email]: 'applicant.login.step.enterCredentials.credentialType.email',
};

interface CredentialTypeSwitchProps {
    onChange: (value: AuthCredentialType) => void;
}

const CredentialTypeSwitch: TranslatedComponent<CredentialTypeSwitchProps> = ({ trls, onChange }) => {
    return (
        <Field<ApplicantLoginFormValues['credentialType']>
            name={CREDENTIAL_TYPE_FIELD}
            type="text"
            render={({ input }) => {
                const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
                    input.onChange(event);

                    if (isCredentialType(event.target.value)) {
                        onChange(event.target.value);
                    }
                };

                return (
                    <Segmented selectedValue={input.value} mode="label" size="large" stretched onChange={handleChange}>
                        <Segment value={AuthCredentialType.Phone}>{trls[TrlKeys[AuthCredentialType.Phone]]}</Segment>
                        <Segment value={AuthCredentialType.Email}>{trls[TrlKeys[AuthCredentialType.Email]]}</Segment>
                    </Segmented>
                );
            }}
        ></Field>
    );
};

export default translation(CredentialTypeSwitch);
