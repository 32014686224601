import {
    AuthCredentialType,
    type Validator,
} from 'src/components/AccountLogin/CombinedLoginCards/ExpApplicantLoginCard/MultiStepForm/types';
import {
    mustBeEmail,
    mustBePhone,
    INVALID_EMAIL_ERROR,
    INVALID_PHONE_ERROR,
} from 'src/components/AccountLogin/CombinedLoginCards/ExpApplicantLoginCard/MultiStepForm/utils';
import type { Country } from 'src/models/allowedCountriesForSmsRegistration';
import type { CountryCode } from 'src/models/countryId';

export const UsernameValidatorMap: Record<AuthCredentialType, Validator> = {
    [AuthCredentialType.Email]: mustBeEmail,
    [AuthCredentialType.Phone]: mustBePhone,
};

export const ErrorMessageMap: Record<AuthCredentialType, typeof INVALID_EMAIL_ERROR | typeof INVALID_PHONE_ERROR> = {
    [AuthCredentialType.Email]: INVALID_EMAIL_ERROR,
    [AuthCredentialType.Phone]: INVALID_PHONE_ERROR,
};

export const getCountryNames = (countries: Country[]): Partial<Record<CountryCode, string>> => {
    return countries.reduce((names, country) => ({ ...names, [country.code]: country.name }), {});
};
