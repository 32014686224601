import { useState } from 'react';
import { useForm } from 'react-final-form';

import { translation, type TranslatedComponent } from '@hh.ru/front-static-app';
import { VSpacingContainer, Button, Title } from '@hh.ru/magritte-ui';

import { ApplicantLoginFormValues } from 'src/components/AccountLogin/CombinedLoginCards/ExpApplicantLoginCard/MultiStepForm/types';

import AdvAgreementField from 'src/components/AccountLogin/CombinedLoginCards/ExpApplicantLoginCard/MultiStepForm/EnterNameStep/AdvAgreementField';
import FirstNameField from 'src/components/AccountLogin/CombinedLoginCards/ExpApplicantLoginCard/MultiStepForm/EnterNameStep/FirstNameField';
import InactiveAccountDeletionField from 'src/components/AccountLogin/CombinedLoginCards/ExpApplicantLoginCard/MultiStepForm/EnterNameStep/InactiveAccountDeletionField';
import LastNameField from 'src/components/AccountLogin/CombinedLoginCards/ExpApplicantLoginCard/MultiStepForm/EnterNameStep/LastNameField';

const TrlKeys = {
    title: 'applicant.login.step.enterName.title',
    action: {
        next: 'applicant.login.step.enterName.action.next',
    },
};

const EnterNameStep: TranslatedComponent = ({ trls }) => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { submit } = useForm<ApplicantLoginFormValues>();

    const handleNextButtonClick = async (): Promise<void> => {
        setIsSubmitting(true);
        await submit();
        setIsSubmitting(false);
    };

    return (
        <VSpacingContainer default={24}>
            <Title Element="h2" size="medium" alignment="center">
                {trls[TrlKeys.title]}
            </Title>
            <VSpacingContainer default={12}>
                <FirstNameField />
                <LastNameField />
                <AdvAgreementField />
                <InactiveAccountDeletionField />
            </VSpacingContainer>
            <VSpacingContainer default={12}>
                <Button
                    type="submit"
                    size="large"
                    mode="primary"
                    style="accent"
                    disabled={isSubmitting}
                    onClick={handleNextButtonClick}
                >
                    {trls[TrlKeys.action.next]}
                </Button>
            </VSpacingContainer>
        </VSpacingContainer>
    );
};

export default translation(EnterNameStep);
